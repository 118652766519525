import { ColoredLogoWithName, WhiteLogoWithName } from "@/components/svg";

export default function LogoWithName({ isDashboard = false, ...props }) {
  return (
    <div className="w-40 lg:w-52 pr-2">
      {isDashboard ? (
        <WhiteLogoWithName className={props.classNames} />
      ) : (
        <ColoredLogoWithName id="logo-with-name" className={props.classNames} />
      )}
    </div>
  );
}
