import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import classNames from "classnames";
import { Mixpanel } from "@/lib/mixpanel";
import { useRouter } from "next/router";
import Image from "next/image";
import { useEffect, useState } from "react";
import { UserSVG, ColoredLogoWithName, DownArrow } from "@/components/svg";

const DashboardSidebar = () => {
  return (
    <aside
      className={classNames(
        "shrink-0 w-64 2xl:w-80 bg-background-secondary-white h-screen sticky top-0",
        "transition-transform duration-200 shadow-sm"
      )}
    >
      <SidebarContents />
    </aside>
  );
};

export default DashboardSidebar;

export const SidebarContents = () => {
  let navigationLinks = [
    {
      title: "Start Here",
      path: "/dashboard/start-here",
    },
    {
      title: "Ship Now",
      path: "/create-shipment",
    },
    {
      title: "Cart",
      path: "/dashboard/cart",
    },
    {
      title: "Orders",
      path: "/dashboard/orders",
      children: [
        {
          path: "/dashboard/orders",
          title: "All",
        },
        {
          path: "/dashboard/orders?tab=Pending%20your%20action",
          title: "Pending",
        },
        {
          path: "/dashboard/orders?tab=In%20Progress",
          title: "In Progress",
        },
        {
          path: "/dashboard/orders?tab=Completed",
          title: "Completed",
        },
        {
          path: "/dashboard/orders?tab=Cancelled",
          title: "Cancelled",
        },
      ],
    },
    {
      title: "Account",
      path: "/dashboard/account/profile",
      children: [
        {
          path: "/dashboard/account/profile",
          title: "Profile",
        },
        {
          path: "/dashboard/account/saved-recipients",
          title: "Saved Recipients",
        },
        {
          path: "/dashboard/account/saved-boxes",
          title: "Saved Boxes",
        },
        {
          path: "/dashboard/account/billing",
          title: "Billing",
        },
        {
          path: "/dashboard/get-a-quote",
          title: "Rates",
        },
        {
          title: "Log Out",
        },
      ],
    },
    {
      title: "Resources",
      path: "/dashboard/resources",
    },
  ];

  // remove Billing if not enabled
  if (process.env.NEXT_PUBLIC_ENABLE_BILLING_MODULE !== "1") {
    navigationLinks.forEach((link) => {
      if (link.children) {
        link.children = link.children.filter(
          (child) => child.title !== "Billing"
        );
      }
    });
  }

  const navItemClasses = classNames(
    "pl-5 py-2 flex text-dark-purple hover:text-light-purple",
    "hover:opacity-80 text-sm rounded"
  );
  const router = useRouter();
  const { data: session } = useSession();
  const [openSections, setOpenSections] = useState({});

  useEffect(() => {
    if (router.isReady) {
      const initialOpenState = navigationLinks
        .filter((link) => link.children)
        .reduce((obj, link) => {
          const open = link.children.some(
            (child) => router.asPath == child.path
          );
          return { ...obj, [link.title]: open };
        }, {});
      setOpenSections(initialOpenState);
    }
  }, [router.isReady]);

  const handleSignOut = async () => {
    // Mixpanel tracking
    Mixpanel.track("Logged Out");
    Mixpanel.reset();
    Sprig("logoutUser");

    await signOut({ callbackUrl: "/sign-in" });
  };

  return (
    <div className="py-6 px-2 space-y-5 relative">
      <div className="px-2 mb-8 mr-8 hidden lg:block">
        <Link href="/">
          <a>
            <ColoredLogoWithName id="sidebar-logo" className="w-48" />
          </a>
        </Link>
      </div>
      <div className="mx-1 flex items-center space-x-4 lg:bg-bg-purple p-2.5 rounded-3xl">
        <div
          className={classNames(
            "rounded-full bg-light-pink bg-opacity-30 w-8 h-8",
            "flex items-center justify-center shadow"
          )}
        >
          {session?.user.image ? (
            <Image
              className="rounded-full"
              src={session.user.image}
              alt="user-image"
              width="36px"
              height="36px"
            />
          ) : (
            <UserSVG />
          )}
        </div>
        <span className="text-sm font-medium line-clamp-2">
          {session?.user_meta?.first_name}
        </span>
      </div>

      {session && (
        <div className="select-none">
          {navigationLinks.map((link) => {
            if (link.title === "Resources" && !session.user_segment)
              return null;

            if (link.children) {
              const isOpen = openSections[link.title];

              return (
                <div key={link.title}>
                  <div className="relative">
                    <Link href={link.path}>
                      <a
                        className={classNames(
                          navItemClasses,
                          link.children.some(
                            (child) => router.asPath === child.path
                          ) && "text-light-purple font-semibold"
                        )}
                        onClick={() =>
                          setOpenSections({
                            ...openSections,
                            [link.title]: true,
                          })
                        }
                      >
                        {link.title}
                      </a>
                    </Link>
                    <span
                      className={classNames(
                        "absolute top-0 bottom-0 right-1/2 translate-x-1/2",
                        "flex items-center justify-center w-12 cursor-pointer text-subtext-gray",
                        "hover:text-light-purple"
                      )}
                      onClick={() =>
                        setOpenSections({
                          ...openSections,
                          [link.title]: !isOpen,
                        })
                      }
                    >
                      <DownArrow
                        color="currentColor"
                        className={classNames(
                          "h-1.5 w-[9px]",
                          "transition-transform duration-50 ease-in-out",
                          isOpen ? "" : "-rotate-90"
                        )}
                      />
                    </span>
                  </div>
                  {isOpen && (
                    <div className="mb-1">
                      {link.children.map((child) => (
                        <div key={child.title}>
                          {child.path && (
                            <Link href={child.path}>
                              <a
                                className={classNames(
                                  navItemClasses,
                                  "!pl-9 !py-1.5",
                                  router.asPath === child.path &&
                                    "text-light-purple font-semibold"
                                )}
                              >
                                {child.title}
                              </a>
                            </Link>
                          )}
                          {child.title === "Log Out" && (
                            <Link href="/sign-in">
                              <a
                                className={classNames(
                                  navItemClasses,
                                  "!pl-9 !py-1.5",
                                  router.asPath === child.path &&
                                    "text-light-purple font-semibold"
                                )}
                                onClick={handleSignOut}
                              >
                                {child.title}
                              </a>
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div key={link.title}>
                  <Link href={link.path}>
                    <a
                      className={classNames(
                        navItemClasses,
                        router.asPath === link.path &&
                          "text-light-purple font-semibold"
                      )}
                    >
                      {link.title}
                    </a>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
