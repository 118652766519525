import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useUser } from "@/hooks/useUser";
import DefaultDashboardSidebar, {
  SidebarContents,
} from "@/components/dashboard/nav/sidebar";

const DashboardSidebarPreferences = dynamic(() =>
  import("@/components/dashboard/nav/sidebar-ecommerce-preferences")
);

const DashboardSidebarEcommerce = dynamic(() =>
  import("@/components/dashboard/nav/sidebar-ecommerce")
);

const DashboardSidebar = ({ isDrawer }) => {
  const router = useRouter();

  const { isShopifyUser, isInternalUser, isEcommerceUser, authStatus } =
    useUser();

  // Redirect to sign-in page if user opens a /dashboard URL without being authenticated
  if (authStatus === "unauthenticated") {
    router.push("/sign-in");
    return;
  }
  if (
    isShopifyUser === undefined ||
    isInternalUser === undefined ||
    isEcommerceUser === undefined
  )
    return;

  if (isShopifyUser || isInternalUser || isEcommerceUser) {
    const path = router.asPath;
    if (
      path.startsWith("/dashboard/account") ||
      path.startsWith("/dashboard/ecommerce") ||
      path === "/dashboard/get-a-quote"
    ) {
      return <DashboardSidebarPreferences />;
    }
    return <DashboardSidebarEcommerce />;
  }
  if (isDrawer) return <SidebarContents />;

  return <DefaultDashboardSidebar />;
};

export default DashboardSidebar;
