import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { getPath } from "./navbar";
import classNames from "classnames";

export default function PopoverPanel({ dropdown, isDirectlyBelowMenuButton }) {
  return (
    <Transition
      as={Fragment}
      leave="transition duration-200 ease-out"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
    >
      <Popover.Panel
        className={classNames(
          "absolute top-full inset-x-0 focus:outline-none",
          isDirectlyBelowMenuButton ? "z-50" : "z-40"
        )}
      >
        {/* Presentational element used to render the bottom shadow, if we put the shadow on the 
        actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
        {!isDirectlyBelowMenuButton && (
          <div
            className="absolute inset-0 top-1/2 bg-white shadow"
            aria-hidden="true"
          />
        )}
        <div
          className={
            isDirectlyBelowMenuButton
              ? "w-max"
              : "relative bg-white border-t border-gray-2"
          }
        >
          <div
            className={isDirectlyBelowMenuButton ? "" : "flex justify-between"}
          >
            <div
              className={classNames(
                "pt-8 pb-14",
                isDirectlyBelowMenuButton ? "" : "invisible"
              )}
            >
              <div className="flex justify-between text-vsm space-x-7 lg:space-x-10 xl:space-x-16">
                {dropdown.sections.map((section) => (
                  <div key={section.name} className="flex-1">
                    {section.name && (
                      <p className="font-medium text-subheading-gray mb-1.5">
                        {section.name}
                      </p>
                    )}
                    <ul role="list" aria-labelledby={`${section.name}-heading`}>
                      {section.items.map((item) => {
                        if (item.name === "FAQ") {
                          return (
                            <li key={item.name}>
                              <a
                                href={item.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block text-dark-purple hover:text-light-purple hover:opacity-80 font-medium py-1.5"
                              >
                                {item.name}
                              </a>
                            </li>
                          );
                        }
                        return (
                          <li key={item.name}>
                            <Link href={getPath(item, dropdown)}>
                              <a className="block text-dark-purple hover:text-light-purple hover:opacity-80 font-medium py-1.5">
                                {item.name}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
}
