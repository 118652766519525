import { Popover } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Link from "next/link";
import LogoWithName from "./logo-with-name";
import Button from "../button";
import PopoverPanel from "./popover-panel";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { XmasDoubleBell } from "../svg";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

export const NAV_CLASS_NAMES = "max-w-7xl mx-auto px-4 lg:px-6 shadow-sm";
export const BASE_NAV_ITEM_CLASS_NAMES = classNames(
  "relative flex items-center -mb-px pt-px text-xs lg:text-vsm z-50",
  "border-b-2 border-transparent focus:outline-none focus:font-medium",
  "transition-colors ease-out duration-200"
);

export default function Navbar({ navigation, setOpen }) {
  const [flatten, setFlatten] = useState(0);
  const router = useRouter();
  const { data: session, status } = useSession();

  const isRegisterPage = router.route.includes("/register");

  // match path has substring of /checkout, /dashboard/setup
  // TODO: 2023-12-30 - remove once checkout is on new dashboard layout
  const hideNavigationDropdowns = router.route.includes("/checkout");
  const hideAll = router.route.includes("/dashboard/setup");

  useEffect(() => {
    const onScroll = () => setFlatten(window.pageYOffset > 100);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const navScrollTransitionClasses = "transition-all duration-300 ease-in-out";

  return (
    <header
      className={classNames("relative bg-white", navScrollTransitionClasses)}
    >
      <nav aria-label="Top" className={NAV_CLASS_NAMES}>
        <div
          className={classNames(
            // bottom margin needed when shrinking navbar so window.pageYOffset doesn't shift
            flatten ? "h-16 md:h-14 md:mb-4 xl:mb-6" : "h-16 md:h-18 xl:h-20",
            "flex justify-between items-center md:justify-end md:flex-row",
            navScrollTransitionClasses
          )}
        >
          {/* Logo */}
          <div className="md:pr-8 lg:pr-16 h-full items-center flex">
            <Link href="/">
              <a className="rounded-sm">
                <span className="sr-only">JustShip Home</span>
                <LogoWithName
                  classNames={classNames(
                    flatten ? "lg:w-4/5" : "w-full",
                    navScrollTransitionClasses
                  )}
                />
              </a>
            </Link>
          </div>

          {/* this is for widths less than 768px */}
          {!hideAll && (
            <div className="flex h-full items-center space-x-2 sm:space-x-6 md:hidden">
              {isRegisterPage ? (
                <div className="flex items-center space-x-4">
                  <AuthSwitchButton
                    callbackUrl={router.query.callbackUrl}
                    variant="primary"
                  />
                </div>
              ) : (
                <>
                  <Link href="/create-shipment">
                    <a
                      className={classNames(
                        "py-2 px-5 rounded-full text-sm font-medium relative",
                        "bg-light-purple hover:bg-dark-purple text-violet-100 hover:text-white"
                      )}
                    >
                      {process.env.NEXT_PUBLIC_THEME === "XMAS" && (
                        <div
                          className={classNames(
                            "absolute -top-4 -right-5 pointer-events-none"
                          )}
                        >
                          <XmasDoubleBell />
                        </div>
                      )}
                      Ship Now
                    </a>
                  </Link>
                </>
              )}
              <button
                type="button"
                className={classNames(
                  "p-2 md:mr-4 rounded-md md:hidden",
                  "text-dark-purple"
                )}
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}

          {hideNavigationDropdowns || hideAll ? (
            <div className="hidden md:block mr-auto"></div>
          ) : (
            <Popover.Group className="hidden mr-auto md:block md:self-stretch">
              <div className="h-full flex md:gap-x-8 xl:gap-x-10">
                {navigation.dropdowns?.map((dropdown) => (
                  <Popover key={dropdown.name} className="flex">
                    {({ open }) => (
                      <>
                        <div className="relative flex">
                          <Popover.Button
                            className={classNames(
                              BASE_NAV_ITEM_CLASS_NAMES,
                              "text-subheading-gray hover:text-light-purple focus:text-dark-purple",
                              open &&
                                "border-dark-purple text-dark-purple font-medium"
                            )}
                          >
                            {dropdown.name}
                          </Popover.Button>
                          <PopoverPanel
                            dropdown={dropdown}
                            isDirectlyBelowMenuButton={true}
                          />
                        </div>

                        <PopoverPanel
                          dropdown={dropdown}
                          isDirectlyBelowMenuButton={false}
                        />
                      </>
                    )}
                  </Popover>
                ))}

                {navigation.pages?.map((page) => {
                  return (
                    <Link key={page.name} href={page.href}>
                      <a
                        className={classNames(
                          BASE_NAV_ITEM_CLASS_NAMES,
                          "text-subheading-gray hover:text-light-purple focus:text-dark-purple"
                        )}
                      >
                        {page.name}
                      </a>
                    </Link>
                  );
                })}
              </div>
            </Popover.Group>
          )}

          {!hideAll && (
            <div className="hidden md:flex items-center md:gap-x-8 xl:gap-x-10">
              {isRegisterPage ? (
                <div className={classNames("flex items-center sm:px-6")}>
                  <AuthSwitchButton callbackUrl={router.query.callbackUrl} />
                </div>
              ) : (
                status !== "loading" && (
                  <>
                    <Link
                      href={session ? "/dashboard/orders" : "/create-shipment"}
                    >
                      <a
                        className={classNames(
                          "py-1.5 px-5 rounded-full border md:text-xs lg:text-vsm",
                          "text-dark-purple hover:text-white border-light-purple hover:bg-light-purple shadow-border-focus"
                        )}
                      >
                        {session ? "Go to Dashboard" : "Ship Now"}
                      </a>
                    </Link>
                    {!session && (
                      <Link href="/sign-in">
                        <a
                          className={classNames(
                            BASE_NAV_ITEM_CLASS_NAMES,
                            "text-subheading-gray hover:text-light-purple focus:text-dark-purple"
                          )}
                        >
                          Sign In
                        </a>
                      </Link>
                    )}
                  </>
                )
              )}
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export const getPath = (item, dropdown) => {
  let path = "";
  if (item.path) {
    return item.path;
  } else if (dropdown.id === "countries") {
    path = `/shipping-singapore-to-${item.name}`;
  } else {
    path = `/${dropdown.name}/${item.name}`;
  }
  return path.toLowerCase().replace(/ /g, "-");
};

const AuthSwitchButton = ({ variant = "alternate", callbackUrl }) => {
  return (
    <Button
      href={callbackUrl ? `/sign-in?callbackUrl=${callbackUrl}` : "/sign-in"}
      variant={variant}
      thin={true}
      className="text-vsm sm:text-sm !px-4 sm:!px-12 font-medium"
    >
      {"Member Login"}
    </Button>
  );
};
